
import { getCookie } from "../util/index.js"
import action from "@/actions.js"
export default {
    data(){
        return {
            roleid:'',
            range:'',
            storeid:'',
            keyword:'',
            tabledata:[],
            isloading:false,
            pagesize:10,
            curpage:1,
            totalcount:null,
            StoreList:[],
            confirmstatus:'',
            afterstatus:""
        }
    },
    beforeCreate() {
        this.PAGELANG = this.LANG.pages.PDDOrderlist;
    },
    
    methods:{
        handleSizeChange(val){
			this.pagesize = val;
			this.init();
		},
		handleCurrentChange(val){
			this.curpage = val;
			this.init();
		},
        formatDate(date) {
			var datetime = new Date(date * 1000);
			var year = datetime.getFullYear();
			var month = ("0" + (datetime.getMonth() + 1)).slice(-2);
			var day = ("0" + datetime.getDate()).slice(-2);
			return year + "-" + month + "-" + day;
		},
        init(){
            this.isloading = true;
            this.axios.get(action.pddafter,{
                headers:{
                    "content-type":"application/json"
                },
                params:{
                    curpage:this.curpage,
					pagesize:this.pagesize,
					keyword:this.keyword,
					storeid:this.storeid,
					range:this.range,
                    confirmstatus:this.confirmstatus,
                    afterstatus:this.afterstatus
                }
            }).then((response)=>{
                console.log(response.data);
                
                this.tabledata = response.data.list;
                this.isloading = false;
                this.curpage = response.data.curpage*1;
				this.totalcount = response.data.totalcount*1;
            });
        },
        GetStore(){
            this.axios.get(action.getstorelist,{
                headers:{
                    "content-type":"application/json"
                }
            }).then((response)=>{
                console.log(response.data);
                this.StoreList = response.data;
            });
        },
        GetOrder(){
            if(!this.storeid){
                this.$message.error("请先选择已授权的店铺");
                return false;
            }
            if(!this.range){
                this.$message.error("请先选择时间");
                return false;
            }
            this.axios.get(action.getpddorder,{
                headers:{
                    "content-type":"application/json"
                },
                params:{
                    storeid:this.storeid,
                    range:this.range,
                }
            }).then((response)=>{
                console.log(response.data);
                if(response.data == "OK"){
                    this.$router.go(0);
                }else{
                    this.$message.error(response.data);
                }
            });
        }
    },
    created:function(){
		this.init();
        this.GetStore();
        this.roleid = getCookie("roleid");
        this.storeid = getCookie("storeid");
	}
}
